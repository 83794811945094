import { Browser } from "@capacitor/browser";
import { Text } from "@clipboard-health/ui-react";
import { Box, Stack } from "@mui/material";
import { isCapacitorPlatform } from "@src/appV2/lib";
import { formatDollarsAsUsd } from "@src/appV2/lib/Money/utils/currency";
import { type ShiftPeriod, shiftPeriodToTimeSlot } from "@src/appV2/Shifts/Shift/types";

import { Button } from "../../components/Button";
import { Divider } from "../../components/Divider";
import { Pill } from "../../components/Pill";
import { ShiftWithContentCard } from "../../Shift/Card/CardWithContent";

const BREAK_PAYMENT_REQUEST_SUPPORT_ARTICLE_URL =
  "https://support.clipboardhealth.com/hc/en-us/articles/21529695509655-Automatic-Breaks-and-Worked-Break-Payment-Requests";

interface BreakPaymentRequestProps {
  shiftTimeSlot: ShiftPeriod;
  shiftStart: string;
  shiftEnd: string;
  shiftAgentReq: string;
  shiftHourlyPay?: string;
  shiftTotalPay?: string;
  workplaceName: string;
  workplaceTimezone: string;
  breakTimeInMinutes: number;
  breakPay: number;
  approvalDate: string;
}

export function BreakPaymentRequest(props: BreakPaymentRequestProps) {
  const {
    shiftTimeSlot,
    shiftStart,
    shiftEnd,
    shiftAgentReq,
    shiftHourlyPay,
    shiftTotalPay,
    workplaceName,
    workplaceTimezone,
    breakTimeInMinutes,
    breakPay,
    approvalDate,
  } = props;

  return (
    <ShiftWithContentCard
      pills={[
        <Pill
          key="pending-break-payment"
          label="Pending break payment"
          color={(theme) => theme.palette.intent?.neutral.text}
          backgroundColor={(theme) => theme.palette.intent?.neutral.background}
        />,
      ]}
      shiftTimeSlot={shiftPeriodToTimeSlot[shiftTimeSlot]}
      shiftStart={shiftStart}
      shiftEnd={shiftEnd}
      shiftAgentReq={shiftAgentReq}
      shiftHourlyPay={shiftHourlyPay}
      shiftTotalPay={shiftTotalPay}
      workplaceName={workplaceName}
      workplaceTimezone={workplaceTimezone}
      shiftSkippedBreakTime={`${breakTimeInMinutes} mins skipped break time`}
      shiftSkippedBreakTimePay={formatDollarsAsUsd(breakPay)}
    >
      <Divider />
      <Stack spacing={4}>
        <Text semibold variant="h6">
          Your request is pending
        </Text>
        <Text variant="body2">
          {workplaceName} will approve or decline your break payment request by {approvalDate}.
        </Text>
        <Box pt={4}>
          <Button
            fullWidth
            variant="outlined"
            onClick={async () => {
              if (isCapacitorPlatform()) {
                await Browser.open({
                  url: BREAK_PAYMENT_REQUEST_SUPPORT_ARTICLE_URL,
                });
              } else {
                window.open(BREAK_PAYMENT_REQUEST_SUPPORT_ARTICLE_URL, "_blank");
              }
            }}
          >
            Learn more
          </Button>
        </Box>
      </Stack>
    </ShiftWithContentCard>
  );
}
