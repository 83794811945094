import { isDefined } from "@clipboard-health/util-ts";

import { type Offer, type OpenShift, type OpenShiftWorkplace } from "./Open/types";
import { resolveShiftType } from "./resolveShiftType";
import { type ShiftWithType } from "./types";

export function convertOpenShiftToShiftWithType(
  shift: OpenShift,
  workplace: OpenShiftWorkplace,
  offer?: Offer
): ShiftWithType {
  const { durationInHours, start, end, timeSlot, qualification, priorityTill, isUrgent, window } =
    shift.attributes;

  const offerAmount = isDefined(offer) ? offer.attributes.pay.amountInMinorUnits / 100 : undefined;
  const originalAmount = durationInHours * (offerAmount ?? 0);

  return {
    _id: shift.id,
    start,
    end,
    time: durationInHours,
    name: timeSlot,
    facilityId: workplace.id,
    agentReq: qualification,
    priorityTill,
    type: resolveShiftType({
      isUrgent,
      window,
      priorityTill,
    }),
    facility: {
      type: workplace.attributes.type,
      userId: workplace.id,
      name: workplace.attributes.name,
      rating: workplace.attributes.rating,
      requiresLunchBreak: workplace.attributes.requiresLunchBreak,
      tmz: workplace.attributes.location.timezone,
      state: workplace.attributes.location.state,
      geoLocation: {
        type: "Point",
        coordinates: [
          workplace.attributes.location.longitude,
          workplace.attributes.location.latitude,
        ],
      },
    },
    pay: offerAmount,
    finalPay: offerAmount,
    originalAmount,
    offer:
      isDefined(offer) && isDefined(offerAmount)
        ? {
            id: offer.id,
            expiresAt: offer.attributes.expiresAt,
            pay: {
              value: offerAmount,
              currency: offer.attributes.pay.currencyCode,
            },
          }
        : undefined,
  };
}
