import { ThemeProvider } from "@mui/material";
import { PullToRefresh } from "@src/appV2/lib/PullToRefresh/PullToRefresh";
import { useExtraWorkedTimeRequests } from "@src/appV2/Shifts/MandatoryBreakPolicy/api/useExtraWorkedTimeRequests";

import { PageHeaderTopRow } from "../../components/PageHeaderTopRow";
import { PageHeaderWrapper } from "../../components/PageHeaderWrapper";
import { getRedesignTheme } from "../../theming";
import { PageContent } from "../components/PageContent";
import { BreakPaymentRequestList } from "./BreakPaymentRequestList";
import { BreakPaymentRequestsLoadingPageState } from "./LoadingPageState";

export function BreakPaymentRequestsPage() {
  const {
    data: extraWorkedTimeRequestsData,
    refetch: refetchExtraWorked,
    isLoading,
    isSuccess,
  } = useExtraWorkedTimeRequests();

  const pendingBreakPaymentRequests = extraWorkedTimeRequestsData?.data ?? [];

  return (
    <ThemeProvider theme={getRedesignTheme()}>
      <PageHeaderWrapper>
        <PageHeaderTopRow
          title={`Break Payment Requests${
            pendingBreakPaymentRequests.length > 0
              ? ` ( ${pendingBreakPaymentRequests.length} )`
              : ""
          }`}
        />
      </PageHeaderWrapper>
      <PageContent>
        <PullToRefresh
          onRefresh={async () => {
            await refetchExtraWorked();
          }}
        />
        {isLoading && <BreakPaymentRequestsLoadingPageState />}
        {isSuccess && (
          <BreakPaymentRequestList extraWorkedTimeRequestsData={extraWorkedTimeRequestsData} />
        )}
      </PageContent>
    </ThemeProvider>
  );
}
