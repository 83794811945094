/* eslint-disable import/max-dependencies */
import { isDefined } from "@clipboard-health/util-ts";
import { ThemeProvider } from "@mui/material";
import { useSentHomeRequests } from "@src/appV2/Agents/api/useSentHomeRequests";
import { DeprecatedGlobalAppV1Paths } from "@src/appV2/App/paths";
import { PullToRefresh } from "@src/appV2/lib/PullToRefresh/PullToRefresh";
import { EnforceLocationPermissionsContainer } from "@src/appV2/LocationPermission/EnforceLocationPermissionsContainer";
import { useOpenNegotiationsCount } from "@src/appV2/Negotiations/api/useOpenNegotiationsCount";
import { useExtraWorkedTimeRequests } from "@src/appV2/Shifts/MandatoryBreakPolicy/api/useExtraWorkedTimeRequests";
import { type Shift, shiftPeriodToTimeSlot } from "@src/appV2/Shifts/Shift/types";
import { useWorkerPendingShiftInvites } from "@src/appV2/Shifts/ShiftInvites/api/useWorkerPendingShiftInvites";
import { useDefinedWorker } from "@src/appV2/Worker/useDefinedWorker";
import { addHours, isBefore, parseISO } from "date-fns";
import { generatePath } from "react-router-dom";

import { CurrentShiftStage } from "../Shift/constants";
import { getFormattedShiftPayBreakdown } from "../Shift/getFormattedShiftPayBreakdown";
import { getShiftCurrentStage } from "../Shift/WorkerShift/getShiftCurrentStage";
import { HighlightedWorkerShiftCard } from "../Shift/WorkerShift/HighlightedCard";
import { getRedesignTheme } from "../theming";
import { useGetWorkerShifts } from "./api/useGetWorkerShifts";
import { WorkerShiftsCalloutsContainer } from "./components/CalloutsContainer";
import { EmptyPageState } from "./components/EmptyPageState";
import { LoadingPageState } from "./components/LoadingPageState";
import { PageContent } from "./components/PageContent";
import { PageTitle } from "./components/PageTitle";
import { ScheduledShiftList } from "./components/ScheduledShiftList";
import { useWorkerUnverifiedShifts } from "./UnverifiedShifts/useWorkerUnverifiedShifts";

function isHighlightedShift(shift?: Shift): boolean {
  if (!isDefined(shift)) {
    return false;
  }

  const currentShiftState = getShiftCurrentStage(shift);

  if (currentShiftState !== CurrentShiftStage.NOT_STARTED) {
    return true;
  }

  const now = new Date();
  const next12Hours = addHours(now, 12);
  const shiftStart = parseISO(shift.start);

  return isBefore(shiftStart, next12Hours);
}

function splitOutHighlightedShift(shifts: Shift[]) {
  const highlightedShift = isHighlightedShift(shifts[0]) ? shifts[0] : undefined;
  const scheduledShifts = isDefined(highlightedShift) ? shifts.slice(1) : shifts;

  return {
    highlightedShift,
    scheduledShifts,
  };
}

export function WorkerShiftsPage() {
  const worker = useDefinedWorker();
  const {
    shifts,
    isLoading: isLoadingShifts,
    refetch: refetchShifts,
  } = useGetWorkerShifts({ worker });
  const {
    data: shiftInvites,
    refetch: refetchShiftInvites,
    isLoading: isLoadingShiftInvites,
  } = useWorkerPendingShiftInvites();
  const {
    data: unverifiedShiftsData,
    refetch: refetchUnverifiedShifts,
    isLoading: isLoadingUnverifiedShifts,
  } = useWorkerUnverifiedShifts();
  const {
    data: openNegotiationsCount,
    refetch: refetchNegotiations,
    isLoading: isLoadingNegotiations,
  } = useOpenNegotiationsCount();
  const {
    data: sentHomeRequestsData,
    refetch: refetchSentHome,
    isLoading: isLoadingSentHome,
  } = useSentHomeRequests();
  const {
    data: extraWorkedTimeRequestsData,
    refetch: refetchExtraWorked,
    isLoading: isLoadingExtraWorked,
  } = useExtraWorkedTimeRequests();

  const { highlightedShift, scheduledShifts } = splitOutHighlightedShift(shifts);

  const highlightedShiftPayBreakdown = getFormattedShiftPayBreakdown(
    highlightedShift?.offer?.pay.value,
    highlightedShift?.finalPay,
    highlightedShift?.originalAmount
  );

  const unverifiedShifts = unverifiedShiftsData?.response ?? [];

  return (
    <ThemeProvider theme={getRedesignTheme()}>
      <PageTitle>My Shifts</PageTitle>
      <PageContent>
        <EnforceLocationPermissionsContainer />
        <PullToRefresh
          onRefresh={async () => {
            await Promise.all([
              refetchShifts(),
              refetchShiftInvites(),
              refetchUnverifiedShifts(),
              refetchNegotiations(),
              refetchSentHome(),
              refetchExtraWorked(),
            ]);
          }}
        />

        <WorkerShiftsCalloutsContainer
          shiftInviteCount={shiftInvites?.data.length ?? 0}
          unverifiedShiftsCount={unverifiedShifts.length}
          activeNegotiationsCount={openNegotiationsCount?.totalNegotiatingShiftsCount ?? 0}
          facilityProposalsCount={openNegotiationsCount?.negotiationsWaitingOnWorker ?? 0}
          extraWorkedRequestsCount={(extraWorkedTimeRequestsData?.data ?? []).length}
          sentHomeRequestsCount={(sentHomeRequestsData ?? []).length}
          isLoadingShiftInvites={isLoadingShiftInvites}
          isLoadingUnverifiedShifts={isLoadingUnverifiedShifts}
          isLoadingNegotiations={isLoadingNegotiations}
          isLoadingSentHome={isLoadingSentHome}
          isLoadingExtraWorked={isLoadingExtraWorked}
        />

        {isLoadingShifts && <LoadingPageState />}
        {!isLoadingShifts && shifts.length === 0 && <EmptyPageState />}

        {!isLoadingShifts && (
          <>
            {isDefined(highlightedShift) && (
              <HighlightedWorkerShiftCard
                href={generatePath(DeprecatedGlobalAppV1Paths.MY_SHIFT_DETAIL, {
                  shiftId: highlightedShift._id,
                })}
                workplaceName={highlightedShift.facility.name}
                workplaceTimezone={highlightedShift.facility.tmz ?? ""}
                shiftStart={highlightedShift.start}
                shiftEnd={highlightedShift.end}
                shiftPosition={highlightedShift.agentReq}
                shiftHourlyPay={highlightedShiftPayBreakdown?.hourlyPay}
                shiftTotalPay={highlightedShiftPayBreakdown?.totalPay}
                shiftStage={getShiftCurrentStage(highlightedShift)}
                shiftTimeSlot={shiftPeriodToTimeSlot[highlightedShift.name]}
              />
            )}

            <ScheduledShiftList shifts={scheduledShifts} />
          </>
        )}
      </PageContent>
    </ThemeProvider>
  );
}
/* eslint-enable import/max-dependencies */
