export enum ShiftType {
  REGULAR = "regular",
  URGENT = "urgent",
  PRIORITY = "priority",
  A_TEAM = "a-team",
}

export enum CurrentShiftStage {
  NOT_STARTED = "NOT_STARTED",
  CLOCKED_IN = "CLOCKED_IN",
  BREAK_STARTED = "BREAK_STARTED",
  BREAK_ENDED = "BREAK_ENDED",
  CLOCKED_OUT = "CLOCKED_OUT",
  PENDING_VERIFICATION = "PENDING_VERIFICATION",
  VERIFIED = "VERIFIED",
}
